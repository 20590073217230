<template>
  <div>
    <new-contact-create v-model="contact"></new-contact-create>
    <a-button class="bg-success text-white ml-4" @click="createContact">
      Contact yaratish
    </a-button>
    <modal v-if="inValid" title="Xato" @close="closeModal">
      <p class="text-white bg-danger p-3 mt-3">
        Iltimos barcha malumotni kiriting
      </p>
    </modal>
  </div>
</template>

<script>
import newContactCreate from '../../components/Contacts/ContactCreate.vue';
import { mapActions } from 'vuex';
export default {
  components: {
    newContactCreate,
  },

  data() {
    return {
      inValid: false,
      contact: {
        phone_number: '',
        email: '',
        message: '',
      },
    };
  },

  methods: {
    ...mapActions({
      postContact: 'contact/postContact',
    }),
    closeModal(bool) {
      this.inValid = bool;
    },

    createContact() {
      for (let i in this.contact) {
        if (this.contact[i] === '') {
          this.inValid = true;
          return;
        }
      }

      this.postContact(this.contact)
        .then(() => {
          this.$notification['success']({
            message: 'Muffaqiyatli qoshildi',
            description: 'Contact qoshildi :)',
          });

          this.contact = {
            phone_number: '',
            email: '',
            message: '',
          };
        })
        .catch(() => {
          this.$notification['error']({
            message: 'Xatolik',
            description: 'Xatolik! Iltimos 9 ta raqam kirgazing',
          });
        });
    },
  },
};
</script>
